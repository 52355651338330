import React from 'react';


const ReceivedChat = ({ message }) => {

  return (
    <div className="flex items-start space-x-2.5 sm:space-x-5">
      <div className="avatar">
        <img className="rounded-full" src="https://admin.haive.tech/placeholders/client-placeholder.png" alt="avatar" />
      </div>
      <div className="flex flex-col items-start space-y-3.5">
        <div className="mr-4 max-w-lg sm:mr-10">
          <div className="rounded-2xl rounded-tl-none bg-grey p-3 text-slate-700 shadow-sm dark:bg-navy-700 dark:text-navy-100">
            {message.message}
          </div>
          <p className="mt-1 ml-auto text-right text-xs text-slate-400 dark:text-navy-300">
            {message.send_at}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ReceivedChat;