import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { profileStart } from '../../store/slices/AdminSlice';
import PageLoader from '../Helper/PageLoader';
import SomethingWentWrong from '../Helper/SomethingWentWrong';
import axios from 'axios';
import ReceivedChat from './ReceivedChat';
import SendChat from './SendChat';
import { addChat, setSelectedChats, updateChatMessages } from '../../store/slices/ChatSlice';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useSocket from "../../hooks/useSocket";
import { ButtonLoader } from '../Helper/Loader';
import { apiUrl } from '../../Environment';
dayjs.extend(customParseFormat);

const SingleChat = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.admin.profile);

  return (
    profile.loading ?
      <PageLoader />
      : Object.keys(profile.data).length > 0 ?
        <ChatSec />
        : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(profileStart())} />
  );
}


const ChatSec = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const selectedChats = useSelector(state => state.chat.selectedChats);
  const highlightChat = useSelector(state => state.chat.highlightChat);
  const chats = useSelector(state => state.chat.chats);

  const [singleMsg, setSingleMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [showChat, setShowChat] = useState(true);
  const [isMin, setIsMin] = useState(false);
  const [first, setFirst] = useState(true);
  const [notify, setNotify] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const lastMsg = useRef();

  const { subscribe, update, sendMessage, unSubscribe } = useSocket();
  const [chat, setChat] = useState({ messages: [], chatId: params.chatId, notify: false });
  const token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token");

  useEffect(() => {
    setChat(chats.find(c => c.chatId === params.chatId) ?? chat);
  }, [chats]);

  useEffect(() => {
    dispatch(setSelectedChats(selectedChats.filter(chat_id => chat_id != params.chatId)))
    getInitialChat();
  }, [params.chatId]);

  useEffect(() => {
    return () => {
      unSubscribe(chat.username)
    }
  }, [chat.username]);

  const getInitialChat = async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("chat_id", params.chatId);
    const response = await axios.post(`${apiUrl}chats/messages`, formData, { headers: { Authorization: 'Bearer ' + token } });
    if (response.data) {
      console.log(response.data);
      setLoading(false);
      dispatch(addChat({
        chatId: params.chatId,
        username: response.data.data.chat.username,
        bot_name: response.data.data.chat.bot_name,
        chat_unique_id: response.data.data.chat.chat_unique_id,
        autoMsg: false,
        messages: response.data.data.chat_messages,
        socketStatus: 0,
      }));
      subscribe(response.data.data.chat.username);

    } else {
      setLoading(false);
      setShowChat(false);
    }
  }

  const onSend = async (e) => {
    e.preventDefault();
    if (singleMsg && !sendLoading) {
      setSendLoading(true);
      let formData = new FormData();
      formData.append("chat_id", params.chatId);
      formData.append("answer", singleMsg);
      const response = await axios.post(`${apiUrl}hooks/send_message`, formData, { headers: { Authorization: 'Bearer ' + token } });
      if (response.data) {
        console.log(response.data);
        const message = response.data.data.chat_message;
        const date = dayjs().format('DD MMM YYYY');
        dispatch(updateChatMessages({
          username: chat.username,
          message: { date, message },
          disableNotify: true,
        }));
        setSingleMsg("");
        sendMessage(chat.username, { date, message });
      }
      else {
        console.log(response);
      }
      setSendLoading(false);
    }
  }

  useEffect(() => {
    if (chat.messages && lastMsg.current) {
      lastMsg.current.scrollIntoView({ behavior: first ? "instant" : "smooth" })
      setFirst(false);
    }
  }, [chat.messages]);

  useEffect(() => {
    if (chat.suggetion && chat.socketStatus === 1) setSingleMsg(msg => msg ? msg + "\n\n" + chat.suggetion : chat.suggetion);
  }, [chat.suggetion]);

  useEffect(() => {
    if (chat.socketStatus === 1)
      setNotify(chat.notify);
  }, [chat.notify]);

  const changeAutoMsg = () => update(chat.username);

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a className="text-info underline" href="' + url + '" target="_blank">' + url + '</a>';
    })
  }

  return (
    <>
      {/* Header */}
      <div className="flex items-center space-x-4 py-5 lg:py-6">
        <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl"> Chats </h2>
        <div className="hidden h-full py-1 sm:flex">
          <div className="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
        </div>
        <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
          <li className="flex items-center space-x-2">
            <Link
              to="/chats"
              className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
              Chats
            </Link>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </li>
          <li>Chat Messages</li>
        </ul>
      </div>

      {/* CHAT MESSAGES */}
      {loading ?
        <PageLoader />
        : showChat ? <>
          <div className="chat-members-section grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-2 lg:gap-6">
            <div className="card flex-row justify-between space-x-2 p-4 sm:p-5">
              <div>
                <div className="flex space-x-1">
                  <h4 className="text-base font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    {chat.chat_unique_id}
                  </h4>
                  <button className="btn h-6 rounded-full px-2 text-xs font-medium badge rounded-full border border-primary text-primary dark:border-accent-light dark:text-accent-light lg:inline-flex">
                    User
                  </button>
                </div>
              </div>
              <div className="avatar h-10 w-10">
                <img className="mask is-squircle" src="https://admin.haive.tech/placeholders/client-placeholder.png" alt="avatar" />
                {/* <div className="absolute right-0 -m-0.5 h-3 w-3 rounded-full border-2 border-white bg-primary dark:border-navy-700 dark:bg-accent"></div> */}
              </div>
            </div>
            <div className="card flex-row justify-between space-x-2 p-4 sm:p-5">
              <div>
                <div className="flex space-x-1">
                  <h4 className="text-base font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    {chat.bot_name}
                  </h4>
                  <button className="btn h-6 rounded-full px-2 text-xs font-medium badge rounded-full border border-error text-error dark:border-accent-light dark:text-accent-light lg:inline-flex">
                    Bot
                  </button>
                </div>
              </div>
              <div className="avatar h-10 w-24 items-center">
                {chat.socketStatus === 1 ?
                  <input
                    className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:!bg-success checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:before:bg-white"
                    type="checkbox"
                    checked={chat.autoMsg}
                    onChange={() => changeAutoMsg()}
                    data-tooltip-id="tooltip-success"
                    data-tooltip-content="Auto Message"
                    data-tooltip-place="bottom"
                  />
                  : <input
                    className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:!bg-error checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:before:bg-white"
                    type="checkbox"
                    checked={true}
                    onChange={() => subscribe(chat.username)}
                  />
                }
                {/* <input
                  className="form-switch is-outline h-5 w-10 rounded-full border border-slate-400/70 bg-transparent before:rounded-full before:bg-slate-300 checked:!border-success checked:before:!bg-success dark:border-navy-400 dark:before:bg-navy-300 mx-2"
                  type="checkbox"
                  data-tooltip-id="tooltip-success"
                  data-tooltip-content="Auto Message"
                  data-tooltip-place="bottom"
                  checked={chat.autoMsg}
                  onChange={() => changeAutoMsg()}
                /> */}
                <img className="mask is-squircle" src="https://admin.haive.tech/placeholders/support-agent-placeholder.jpg" alt="avatar" />
                {/* <div className="absolute right-0 -m-0.5 h-3 w-3 rounded-full border-2 border-white bg-error dark:border-navy-700 dark:bg-accent"></div> */}
              </div>
            </div>
          </div>
          <div className="single-chat-box">
            {chat.messages.length > 0 ?
              <div className="scrollbar-sm grow overflow-y-auto px-[calc(var(--margin-x)-.5rem)] py-5 transition-all duration-[.25s]">
                {chat.messages.map((dailyChat, i) =>
                  <div className="space-y-5" key={i}>
                    <div className="mx-4 flex items-center space-x-3">
                      <div className="h-px flex-1 bg-slate-200 dark:bg-navy-500"></div>
                      <p>{dailyChat.date}</p>
                      <div className="h-px flex-1 bg-slate-200 dark:bg-navy-500"></div>
                    </div>

                    {dailyChat.messages.map((message, i) => message.sent_by === "user" ?
                      <ReceivedChat message={message} key={i} />
                      : <SendChat message={message} key={i} />
                    )}

                    <div ref={lastMsg} />
                  </div>
                )}
              </div>
              : "No Chat"
            }
            {chat.socketStatus === 1 ?
              <div className="client-chat-popout-card-left-footer-form single-chat-form">
                <form onSubmit={onSend}>
                  <div>
                    <label className="flex -space-x-px">
                      <textarea
                        className="form-control w-full rounded-l-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400"
                        placeholder="Write your message"
                        type="text"
                        value={singleMsg}
                        onChange={e => setSingleMsg(e.target.value)}
                      />
                      <div className="flex items-center justify-center rounded-r-lg input-group-text px-3 py-2">
                        <button
                          className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
                          type="submit"
                        >
                          {sendLoading ?
                            <ButtonLoader size={5} />
                            : "Send"
                          }

                          {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#00195F"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M.172 3.708A2.745 2.745 0 01.917.713 2.745 2.745 0 013.965.282l18.09 8.444A3.584 3.584 0 0124 10.999H4.048L.229 3.835a1.446 1.446 0 01-.057-.127zM4.062 13L.309 20.175a.944.944 0 00-.054.122 2.744 2.744 0 00.749 2.993 2.756 2.756 0 001.841.708c.409 0 .819-.092 1.201-.279l18.011-8.438a3.582 3.582 0 001.945-2.28H4.062z"></path>
                        </svg> */}
                        </button>
                      </div>
                    </label>
                  </div>
                </form>
              </div>
              : chat.socketStatus === 2 ?
                <div className="client-chat-popout-card-left-footer-form single-chat-form">
                  <div className="flex -space-x-px">
                    <div
                      className="form-control w-full border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 chat-msg"
                    >
                      <span className="text-error w-full">Monitoring mode </span>
                      - Chat is already connected in another device
                    </div>
                    <button
                      className="btn w-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
                      type="button"
                      onClick={() => subscribe(chat.username)}
                    >
                      Reconnect
                    </button>
                  </div>
                </div>
                : null
            }
          </div>
        </>
          : <SomethingWentWrong buttonText="Back" handleClick={() => navigate("/chats")} />
      }
    </>
  );
}

export default SingleChat;